<!--
.. Added by Kishore Jalleda
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <dashboard-card
      :loading="loading"
      type="line"
      :options="chartOptions"
      :series="series"
      title="% of Tasks Resolved On Time"
    />
</template>

<script>
import { forEach } from "lodash"
import DashboardCard from "@/dashboard/DashboardCard.vue"

export default {
    name: "TaskResolvedOnTimeCard",

    props: {
        value: {
        type: Object,
        default: function () {
            return {}
        },
        },
        interval: {
        type: String,
        default: function () {
            return "Month"
        },
        },
        loading: {
        type: [String, Boolean],
        default: function () {
            return false
        },
        },
    },

    components: {
        DashboardCard,
    },

    computed: {
        series() {
        let series = { name: "% Resolved On Time", data: [] }

        forEach(this.value, function (value) {
            let count = 0

            value.forEach(function (item) {
            if (item.resolved_at && item.resolve_by) {
                const resolvedAt = new Date(item.resolved_at)
                const dueDate = new Date(item.resolve_by)

                if (resolvedAt <= dueDate) {
                count++
                }
            }
            })

            const percentage = (count / value.length) * 100
            series.data.push(percentage.toFixed(2))
        })

        return [series]
        },
        chartOptions() {
        return {
            chart: {
            height: 350,
            type: "line",
            animations: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
            },
            xaxis: {
            categories: Object.keys(this.value) || [],
            title: {
                text: this.interval,
            },
            },
            dataLabels: {
            enabled: true,
            },
            stroke: {
            curve: "smooth",
            },
            markers: {
            size: 1,
            },
            yaxis: {
            title: {
                text: "% Resolved On Time",
            },
            },
            legend: {
            position: "top",
            },
        }
        },
    },
}
</script>
